@regular: 'Proxima Nova Regular';
@bold: 'Proxima Nova Bold';
@black: 'Proxima Nova Black';
@extrabold: 'Proxima Nova Extrabold';
@light: 'Proxima Nova Light';


html {
    min-width: 320px;
    overflow-x: hidden;
}

body {
     background: #1d1d1d;
     font-family: @regular;
     font-size: 16px;
     color: #fff;
     overflow: hidden;
    padding-top: 40px;
}

.grecaptcha-badge {
    opacity: 0;
    z-index: -1;
}

.g-recaptcha {
    position: fixed;
    top: 50%;
    left: 50%;
}

a, a:visited {
    color: #fff;
    text-decoration: underline;
}

a:hover {
    text-decoration: none;
}

* {
    padding: 0;
    margin: 0; 
    box-sizing: border-box;
    outline: none;
}

li {
    list-style: none;
}

img {
    max-width: 100%;
    display: block;
}

.wrapper {
    margin: 0 auto;
    width: 320px;
    padding: 0 10px;
    position: relative;

    @media(min-width: 480px) {
        width: 480px;
    }
}

.clearfix {
    float: none;
    clear: both;
    width: 100%;
    height: 0;
}

.rouble {
    font-family: 'Rouble';
}

.table {
    display: table;
    border-spacing: 0;
    table-layout: fixed;

    .tr {
        display: table-row;
    }

    .td {
        display: table-cell;
    }
}


.button {
    background: #ff8400;
    border-radius: 25px;
    text-align: center;
    font-size: 13px;
    font-family: @bold;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    transition-duration: 0.2s;
    color: #fff;
    border: none;
    cursor: pointer;

    &:before {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        content: "";
    }

    span {
        display: inline-block;
        vertical-align: middle;
    }

    &:hover {
        background: #ffa800;
    }
}

h1 {
    font-family: @black;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;

    @media(min-width: 480px) {
        font-size: 28px;
        line-height: 35px;
    }

    span.orange {
        color: #ffa800;
    }

    .city {
        display: none;
    }

    .sup {
        font-size: 13px;
        vertical-align: top;
        display: inline-block;
        margin-top: -6px;
    }
}

h2 {
    font-size: 26px;
    font-family: @black;
    letter-spacing: 0.02em;
}

h3 {
    font-size: 20px;
    font-family: @black;
}

label.animation {
    position: relative;
    display: block;
    padding-bottom: 17px;

    input {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #fff;
        display: block;
        width: 100%;
        background: transparent;
        padding: 6px 40px 3px 10px;
        font-size: 14px;
        position: relative;
        color: #fff;
    }

    textarea {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #fff;
        display: block;
        width: 100%;
        background: transparent;
        padding: 6px 10px 3px;
        font-size: 14px;
        position: relative;
        color: #fff;
        max-width: 100%;
        min-width: 100%;
        height: 27px;
        min-height: 27px;
        max-height: 60px;
    }

    .border {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 17px;
        height: 0;
        background: #ff8400;
        transition-duration: 0.2s;
    }

    .input_title {
        position: absolute;
        bottom: 20px;
        left: 10px;
        font-family: @light;
        transition-duration: 0.2s;
        font-size: 14px;
    }

    input:focus + .input_title, input.filled + .input_title, textarea:focus + .input_title, textarea.filled + .input_title {
        bottom: -1px;
    }

    input:focus + .input_title + .border {
        height: 2px;
    }

    &.error {
        .input_title {
            color: #ff8400;
        }

        .border {
            height: 2px;
        }

        &:after {
            content: "!";
            font-family: 'Proxima Nova Black';
            color: #ff8400;
            font-size: 14px;
            height: 24px;
            line-height: 1;
            padding-top: 4px;
            text-align: center;
            border-radius: 12px;
            position: absolute;
            right: 10px;
            bottom: 21px;
            border: 2px solid #ff8400;
            width: 24px;
            box-sizing: border-box;
        }
    }
}

select {
    display: none;
}

.cs-select {
    .cs-placeholder {
        border: none;
        border-bottom: 1px solid #fff;
        display: block;
        width: 100%;
        background: transparent;
        padding: 6px 10px;
        font-size: 14px;
        position: relative;
        color: #fff;
        background: url(../img/select.png) right 15px center no-repeat;
    }

    .cs-options {
        position: absolute;
        top: 100%;
        margin-top: -2px;
        left: 0;
        width: 100%;
        z-index: 3;
        z-index: -1;

        li {
            background: #fff;
            color: #1d1d1d;
            font-size: 14px;
            font-family: @light;
            padding: 9px 10px 9px 30px;
            margin: 1px 0;
    
            -webkit-transform-origin: 50% 50% -200px;
            transform-origin: 50% 50% -200px;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
            transition: transform 0.3s, opacity 0.3s;
            opacity: 0;
            -webkit-transform: rotate3d(0,1,0,90deg);
            transform: rotate3d(0,1,0,90deg);
    
            &:first-child{ 
                display: none;
            }
    
            &:nth-child(3) {
                transition-delay: 0.05s;
            }
            &:nth-child(4) {
                transition-delay: 0.1s;
            }
            &:nth-child(5) {
                transition-delay: 0.15s;
            }
            &:nth-child(6) {
                transition-delay: 0.2s;
            }
            &:nth-child(7) {
                transition-delay: 0.25s;
            }
    
            &:hover {
                background-color: #ffa800;
            }
    
            &.sel {
                background: url(../img/selected.png) right 15px center no-repeat #ffa800;
            }
        }
    }

    &.cs-active {
        .cs-options {
            z-index: 1;
    
            li {
                opacity: 1;
                -webkit-transform: rotate3d(0,1,0,0deg);
                transform: rotate3d(0,1,0,0deg);
                pointer-events: auto;
                -webkit-transform: translate3d(0,0,0);
                transform: translate3d(0,0,0);
            }
        }

    }
}
.polite {
    font-size: 11px;
    line-height: 14px;

}

.popup_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #212121;
    opacity: 0;
    transition-duration: 0.4s;
    z-index: 100;
    display: none;

    &.show {
        display: block;
        opacity: 0.9;
        transition-duration: 0.4s;
    }
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    background: url(../img/popup.png) top center no-repeat #212121;  
    width: 300px;
    margin-left: -150px;
    padding: 45px 35px 30px;
    height: auto;
    visibility: hidden;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 101;

    .close {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 50%;
        border: 1px solid #fff;
        fill: #fff;
        transition-duration: 0.3s;
        cursor: pointer;

        &:hover {
            fill: #ff8400;
            border-color: #ff8400;
        }
    }

    .title {
        font-family: @black;
        font-size: 20px;
        display: block;
        text-align: center;
        margin-bottom: 20px;
    }

    .desc {
        font-size: 16px;
        line-height: 24px;
        display: block;
        text-align: center;
    }

    label {
        margin: 20px 0; 
    }

    .button {
        width: 200px;
        display: block;
        margin: 20px auto 0;
    }

    &.show {
        visibility: visible;
        opacity: 1;
        
        -webkit-transform: scale(1) rotateX(0deg);
        -moz-transform: scale(1) rotateX(0deg);
        transform: scale(1) rotateX(0deg);
    }

    &#callback {
        margin-top: -175px;
    }

    &#success {
        margin-top: -85px;
        background: url(../img/popup.png) top -100px center no-repeat #212121; 
    }
}

header {
    background: url(../img/header.png) center top no-repeat;
    position: relative;
    z-index: 3;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 910px;
        height: 4px;
        width: 100%;
        background: #1d1d1d;            
        transform: matrix(1, 0.124, 0, 1, 0, 0);
    }

    .wrapper {
        padding: 0;
        z-index: 2;
    }

    .phone {
        font-family: @bold;
        font-size: 18px;
        text-decoration: none;
        float: right;
        margin: 7px 0 10px;
        letter-spacing: 0.02em;
        clear: right;
        margin-right: 30px;

        span {
            color: #ffa800;
            font-size: 25px;
        }
    }

    .callback {
        width: 240px;
        float: right;
        margin-bottom: 17px;
        clear: right;
        margin-right: 10px;

        @media(min-width: 480px) {
            margin-bottom: 0;
        }
    }

    .logo {
        width: 193px;
        height: 54px;
        margin-bottom: 10px;
        margin-left: 10px;

        @media(min-width: 480px) {
            width: 277px;
            height: 78px;
        }
    }

    .desc {
        font-size: 16px;
        line-height: 20px;
        font-family: @bold;
        margin-left: 10px;
        display: block;
        margin-right: 10px;
        white-space: pre-line;

        @media(min-width: 480px) {
            font-size: 20px;
            line-height: 25px;
        }
    }

    .advantages {
        margin: 50px 10px 70px;

        @media(min-width: 480px) {
            width: 330px;
            margin-bottom: 95px;
        }

        li {
            font-size: 15px;
            line-height: 18px;
            position: relative;
            padding-left: 65px;
            margin: 20px 0;

            strong {
                font-family: @extrabold;
            }

            sup {
                font-size: 9px;
            }

            .icon {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 47px;
                height: 47px;
                fill: #fff;
            }

            &.lab:before {
                background-position: 0 0;  
            }
            &.upper:before {
                background-position: 0 -57px;  
            }
            &.gost:before {
                background-position: 0 -112px;  
            }
        }
    }

    .delivery {
        background: #212121;
        position: relative;
        padding: 10px 20px 35px; 
        width: 100%;

        @media(min-width: 480px) {
            width: 460px;
            margin: 0 auto;
            padding: 10px 45px 70px;

        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: -35px;
            width: 0;
            height: 0;
            border-right: 320px solid transparent;
            border-bottom: 35px solid #212121;

            @media(min-width: 480px) {                
                border-right: 460px solid transparent;
                border-bottom: 60px solid #212121;
                top: -60px;
            }
        }

        .delivery_title {
            font-size: 26px;
            display: block;
            font-family: @black;
            margin-bottom: 13px;
        }

        .delivery_desc {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 12px;
            display: block;

            &>span {
                font-family: @extrabold;
                color: #ff9700;
            }

            sup {
                font-size: 11px;
            }
        }

        ul {
            padding-left: 35px;

            li {
                list-style: square;
                margin: 8px 0;
                font-size: 14px;

                strong {
                    font-family: @extrabold;
                }
            }
        }
    }

    .form_back {
        margin-top: -55px;
        position: relative;
        z-index: 2;

        @media(min-width: 480px) { 
            width: 460px;
            margin: 0 auto;
            margin-top: -60px;
        }

        .form_title {
            color: #ff8400;
            font-size: 20px;
            line-height: 20px;
            font-family: @black;
            margin-bottom: 23px;
            display: block;
        }

        form {
            background: url(../img/header_form.png) center top no-repeat;
            width: 100%;
            padding: 85px 14px 30px;

             @media(min-width: 480px) { 
                 background-image: url(../img/header_form_m_l.png);
                 padding: 60px 85px 30px;
             }

            label {
                margin: 12px 0;
            }

            .polite {
                text-align: right;
            }

            .button {
                width: 170px;
                margin: 0 auto;
                display: block;
                margin-top: 25px;
            }
        }
    }


    .map {
        height: 295px;
        position: relative;
        border-top: 5px solid #ff8400;
        border-bottom: 5px solid #ff8400;

        @media(min-width: 480px) {  
            width: 460px;
            margin: 0 auto;

        }

        .ymap {
            width: 100%;
            height: 100%;
            position: absolute;
        }

        .address_desc {
            position: absolute;
            z-index: 2;            
            width: 195px;
            transform-origin: calc(100% + 2em) 50%;
            transform: translate3d(0,50%,0) rotate3d(1,1,1,-30deg) translate(-100%, -100%);
            opacity: 0;
            transition-duration: 0.2s;

            &.opened {
                transform: matrix(1, 0.124, 0, 1, 0, 0) translate(-100%, -100%);
                opacity: 1;                
            }

            // top: 70px;
            // left: 25px;

            .close {
                position: absolute;
                right: 7px;
                top: 0;
                color: #fff;
                font-size: 20px;
                z-index: 10;
                text-decoration: none;
            }

            @media(min-width: 480px) { 
                // left: 55px;
            }

            .text {
                background: #212121;
                padding: 25px 15px 30px;
                position: relative;
                z-index: 3;
            }

            .orange {
                position: absolute;
                background: #ff8400;
                top: -5px;
                left: -5px;
                width: 100%;
                height: 100%;
            }

            .address_title {
                color: #ff8400;
                font-size: 14px;
                display: block;
                transform: matrix(1, -0.124, 0, 1, 0, 0);
                margin-bottom: 8px;
            }

            .address {
                display: block;
                transform: matrix(1, -0.124, 0, 1, 0, 0);
                font-size: 14px;
                line-height: 20px;
                color: #fff;
            }
        }
    }
 
}

section.price {
    background: #212121;
    padding-bottom: 15px;

    &>.wrapper {
        width: 100%;
        padding: 0;
    }

    h2 {
        color: #ff8400;
        background: #212121;
        padding: 50px 0 30px;
        position: relative;
        z-index: 2;
    }

    .title {
        background: #212121;
        font-size: 16px;
        text-transform: uppercase;
        cursor: pointer;
        position: relative;
        z-index: 2;
        transition-duration: 0.3s;

        .wrapper {
            padding: 19px 10px 19px 70px;
            transition: background 0.3s;

            .icon {
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
                width: 42px;
                height: 42px;
                fill: currentColor;
            }
        }

        &:hover {
            color: #ff8400;

        }

        &.active {
            .wrapper {
                background: #ffa800;
                color: #fff;
            }
            &.product_concrete .wrapper:before {
                background-position: 0 -187px;
            }
    
            &.install .wrapper:before {
                background-position: 0 -239px;
            }
    
            &.placing .wrapper:before {
                background-position: 0 -292px;
            }
    
            &.keram .wrapper:before {
                background-position: 0 -338px;
            }
    
            &.hydro .wrapper:before {
                background-position: 0 -392px;
            }
        }
    }

    .content {
        overflow: hidden;
        max-height: 0;
        transition-duration: 0.4s;
        position: relative;
        z-index: 2;
        background: #1d1d1d;
    }

    .table {
        font-size: 13px;
        table-layout: auto;
        margin-bottom: 20px;

        .header {
            color: #ffa800;
            font-size: 16px;
            font-family: @bold;

            .td {
                vertical-align: bottom;
                border-bottom: 1px solid #848484;
            }
        }

        .td {
            text-align: right;
            vertical-align: middle;
            padding: 8px 0;
            min-width: 60px;

            sup {
                font-size: 9px;
            }

            &:nth-child(2), &:nth-child(3) {
                text-align: center;
            }

            @media(max-width: 479px) {

                &.plast {
                    display: none;
                }
            }

            &:first-child {
                width: 160px;
                padding-right: 20px;
                text-align: left;

                @media(min-width: 480px) {
                    width: 120px;
                    padding-right: 10px;
                    padding-left: 10px;
                }
            }

            &:last-child {
               @media(min-width: 480px) {
                    padding-right: 10px;
                } 
            }
        }

        .tr:nth-child(2) {
            .td {
                padding-top: 20px;
            }
        }
    }

    .title.active + .content {
        max-height: 1000px;
    }

    .sales {
        text-align: center;
        font-size: 16px;
        font-family: @bold;

        @media(min-width: 480px) {
            text-align: right;
            font-family: @black;
            float: left;
            width: 50%;
            margin-right: 10px;
            padding-right: 10px;
            font-size: 18px;
        } 

        sup {
            font-size: 11px;
        }

        span {
            display: block;
            margin: 0 auto;
            color: #ff8400;
        }
    }

    .button {
        display: block;
        margin: 10px auto 15px;
        width: 200px;

         @media(min-width: 480px) { 
             float: left;
             margin: 0 0 30px;
         }
    }

    .desktop_sales {
        display: none;
    }
}

 section.seconds60 {
    background: url(../img/seconds60_m.png) center top no-repeat;
    height: 630px;
    position: relative;
    margin-bottom: 20px;

    .title_d, .desc_d {
        display: none;
    }

    .title {
        font-size: 26px;
        line-height: 30px;
        letter-spacing: 0.02em;
        font-family: @black;

        @media(min-width: 480px) { 
            margin-bottom: 25px;
        }

        span {
            color: #ff8400;
        }
    }

    .wrapper {
        width: 460px;
        padding: 0;
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        left: 50%;
        margin-left: -230px;
        top: 80px;

        &:before {
            content: "";
            position: absolute;
            top: -60px;
            left: 0;
            width: 0;
            height: 0;            
            border-right: 460px solid transparent;
            border-bottom: 60px solid rgba(0, 0, 0, 0.4);
        }

        &:after {
            content: "";
            position: absolute;
            bottom: -60px;
            left: 0;
            width: 0;
            height: 0;            
            border-left: 460px solid transparent;
            border-top: 60px solid rgba(0, 0, 0, 0.4);;
        }
    }

    form {
        width: 300px;
        margin: 0 auto;

        @media(min-width: 480px) { 
            width: 335px;
        }

        .form_title {
            display: none;
        }

        label {
            width: 300px;
            margin: 14px 0;
            display: block;
            position: relative;

            &.short {
                width: 200px;
            }
        }

        .button {
            width: 200px;
            margin: 15px 0 0 auto;
            display: block;
        }
    }
}

section.equipment {
    position: relative;
    z-index: 2;
    
    h2 {
        margin-bottom: 20px;
    }

    ul {
        li {
            display: block;
            padding: 20px 15px;
            border-left: 5px solid #ff8400;
            background: #212121;
            margin: 15px 0;
            width: 100%;
            position: relative;

            .title {
                font-size: 18px;
                font-family: @black;
                margin-bottom: 5px;
                display: block;
            }

            .img {
                width: 220px;
                height: 160px;
                display: block;
                margin-bottom: 5px;
            }

            .price {
                color: #ffa800;
                font-size: 14px;
                line-height: 20px;
                font-family: @black;
                display: block;
                margin-bottom: 15px;
            }

            .desc {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}

section.about {
    background: #ebebeb;
    position: relative;
    margin-top: 165px;
    padding: 1px 0;
    margin-bottom: 175px;

    .nav {
        display: none;

        a .icon {
            display: none;
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: -235px;
        left: 50%;
        margin-left: -960px;
        border-right: 1920px solid transparent;
         border-bottom: 235px solid #ebebeb;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: -235px;
        left: 50%;
        margin-left: -960px;
        border-left: 1920px solid transparent;
        border-top: 235px solid #ebebeb;
    }

    h2 {
        margin-bottom: 30px;
        margin-top: -65px;
    }

    h2, h3 {
        color: #1d1d1d;
    }

    ul.pluses {
        margin-bottom: 30px;

        li {
            color: #1d1d1d;
            font-size: 16px;
            line-height: 20px;
            display: block;
            position: relative;
            padding-left: 70px;
            margin: 20px 0;   
            min-height: 54px;             

            .icon {
                position: absolute;
                left: 0;
                top: 0;
                width: 60px;
                height: 54px;
                fill: #ff8400;
            }

            &.experience {
                top: 3px;
            }

            &.staff .icon {
                top: 5px;                
            }
            &.department .icon {
                top: 5px;
            }
            &.stock .icon {
                top: 5px;
            }
            &.clients .icon {
                top: -10px;
            }
            &.gost .icon {
                fill-rule: evenodd;
            }

            @media(min-width: 480px) {
                &.experience .icon, &.staff .icon, &.factory .icon, &.stock .icon, &.gost .icon, &.call .icon {                    
                    top: -5px;
                }
            }        
             

            strong {
                font-family: @black;
            }
        }
    }

    .manufacture {
        margin-bottom: 50px;

        .caroufredsel_wrapper {
            margin-left: -302px!important;
            padding: 0 302px;
            box-sizing: content-box;

            @media(min-width: 480px) {
                margin-left: -223px!important;
            }

            &:after {
                content: '';
                position: absolute;
                width: 302px;
                height: 166px;
                top: 0;
                left: 302px;
                box-sizing: border-box;
                border: 6px solid #ff8400;
            }
        }

        ul.manufacture-slider {

            li {
                width: 302px;
                float: left;
                position: relative;
                height: 166px;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    background: rgba(29, 29, 29, 0.5);
                    opacity: 1;
                    transition-duration: 0.2s;
                }

                &.center:after {
                    opacity: 0;
                    transition-duration: 0.2s;
                }
            }
        }
    }

    .certificates {
        margin-bottom: 30px;

        .caroufredsel_wrapper {
            margin-left: -83px!important;
            box-sizing: content-box;
            padding: 0 150px;
        }

        ul.certificates-slider {
            li {
                float: left;
                height: 230px;
                width: 155px;
                line-height: 230px;
                text-align: center;
                padding: 10px 4px;

                @media(min-width: 480px) { 
                    width: 153px;
                }

                a {
                    display: block;
                }

                img {
                    display: inline-block;
                    vertical-align: middle;
                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.35);
                }
            }
        }
    }

    .lab {

        @media(min-width: 480px) { 
            .wrapper {
                width: 385px;
            }
        }
        p {
            font-size: 16px;
            line-height: 20px;
            color: #212121;
            margin-bottom: 10px;
        }

        img {
            width: 280px;
            margin: 0 auto;

            &.sec {
                display: none;
            }
        }

        form {
            width: 460px;
            margin-left: -80px;
            background: url(../img/lab_form_m.png) top center no-repeat;
            height: 506px;
            padding: 35px 105px 0;
            margin-top: 20px;

            .form_title {
                font-size: 20px;
                font-family: @black;
            }

            @media(min-width: 480px) { 
                margin-left: -48px;
            }

            label {
                margin: 18px 0;
            }

            .polite {
                margin-left: 13px;
                margin-top:  40px;
            }

            .button {
                display: block;
                width: 200px;
                margin: 20px auto 0;
            }
        }
    }

    .park {
        h3 {
            margin-bottom: 40px;
        }

        p {
            color: #1d1d1d;
            font-size: 16px;
            line-height: 20px;
            margin: 25px 0;
        }

        .caroufredsel_wrapper {
            margin-left: -302px!important;
            box-sizing: content-box;
            padding: 0 302px;

            @media(min-width: 480px) {
                margin-left: -223px!important;
            }

            &:after {
                content: '';
                position: absolute;
                width: 302px;
                height: 166px;
                top: 0;
                left: 302px;
                box-sizing: border-box;
                border: 6px solid #ff8400;
            }
        }

        ul.park-slider {

            li {
                width: 302px;
                float: left;
                position: relative;
                height: 166px;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    background: rgba(29, 29, 29, 0.5);
                    opacity: 1;
                    transition-duration: 0.2s;
                }

                &.center:after {
                    opacity: 0;
                    transition-duration: 0.2s;
                }
            }
        }
    }
}

section.clients {
    margin: -240px 0;
    padding: 240px 0 150px;
    background: #212121;

    h2 {
        margin-bottom: 30px;
    }

    .big_clients .caroufredsel_wrapper {
        margin-left: -302px!important;
        box-sizing: content-box;
        padding: 0 302px;

        @media(min-width: 480px) {
            margin-left: -223px!important;
        }
        
        &:after {
            content: '';
            position: absolute;
            width: 302px;
            height: 234px;
            top: 0;
            left: 302px;
            box-sizing: border-box;
            border: 6px solid #ff8400;
            z-index: 3;
        }
            
    }

    ul.clients-slider {
        margin-bottom: 20px;

        li {
            width: 302px;
            float: left;
            position: relative;
            z-index: 1;

            .for_click {
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 234px;
                left: 0;
                top: 0;
                display: block;
                cursor: pointer;
            }

            &.center {
                z-index: 2;
            }

            img {
                height: 234px;
                margin-bottom: 25px;
                display: block;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                width: 100%;
                height: 234px;
                left: 0;
                background: rgba(29, 29, 29, 0.5);
                opacity: 1;
                transition-duration: 0.2s;
                z-index: 1;
            }

            &.center:after {
                opacity: 0;
                transition-duration: 0.2s;
            }

            .title {
                font-size: 18px;
                line-height: 20px;
                color: #ff8400;
                font-family: @black;
                display: block;
                opacity: 0;
                transition-duration: 0.4s;
                margin-bottom: 25px;
            }

            ul {
                padding: 0 10px 0 40px;
                opacity: 0;
                transition-duration: 0.4s;
            
                li {
                    list-style: square;
                    font-size: 16px;
                    width: 100%;
                    float: none;
                    margin: 15px 0;
                    
                    sup {
                        font-size: 11px;
                    }

                    &:after {
                        display: none;
                    }

                    span.value {
                        display: block;
                        color: #ff8400;
                    }
                }
            }

            @media(min-width: 480px) {

                .title {
                    width: 460px;
                    margin-left: -80px;
                }

                ul {
                    padding: 0;

                    li {
                        span.value {
                            display:inline-block;
                        }

                        span.key {
                            display: inline-block;
                            vertical-align: bottom;
                            width: 127px;
                        }
                    }
                }
            }

            &.center ul, &.center .title {
                opacity: 1;
                transition-duration: 0.4s;
            }
        }
    }

    h3 {
        margin-bottom: 15px;
    }

    .logos {
        text-align: right;
        z-index: 2!important;

        li {
            display: inline-block;
            vertical-align: middle;
            width: 135px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            margin-right: 15px;

            img {
                display: inline-block;
                vertical-align: middle;
                max-height: 100%;
            }
        }

        @media(min-width: 480px) {
            text-align: center;       
        }
    }
}

section.watch {
    background: url(../img/watch_m.png) top center no-repeat;
    padding-top: 160px;
    margin-top: -85px;
    padding-bottom: 200px;
    position: relative;

    &:before, &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 117px;
        height: 3px;
        width: 100%;
        background: #212121;            
        transform: matrix(1, 0.124, 0, 1, 0, 0);
    }

    &:after {
        background: #1d1d1d;
        top: auto;
        bottom: 124px;

        @media(min-width: 480px) { 
            bottom: 121px;
        }
    }

    .connect {

        .pluses {
            margin-bottom: 20px;

            .number {
                font-size: 38px;
                line-height: 30px;
                display: block;
                color: #ff8400;
                letter-spacing: 0.02em;
                font-family: @black;
            }

            .text {
                font-size: 16px;
                line-height: 18px;
                text-transform: uppercase;
                white-space: pre-line;
            }
        }

        .title {
            font-size: 26px;
            letter-spacing: 0.02em;
            font-family: @black;
            display: block;
            margin-top: 22px;
            padding-top: 29px;
            position: relative;
            margin-bottom: 18px;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 2px;
                width: 140px;
                background: #fff;
            }
        }

        .content {
            font-size: 16px;
            line-height: 24px;
            display: block;
            width: 305px;
        }
    }

    form {
        margin-top: 120px;
        padding: 0 29px;

        .form_title {
            display: block;
            position: relative;
            padding-top: 60px;
            font-size: 20px;
            line-height: 24px;
            font-family: @black;
            margin-bottom: 26px;

            .icon {
                position: absolute;
                top: 0;
                left: 0;
                width: 40px;
                height: 40px;
                fill: #fff;
            }
        }

        label {
            margin: 20px 0;
            width: 200px;
        }

        .button {
            margin-top: 28px;
            background: #212121;
            width: 200px;

            &:hover {
                background: #383838;
            }
        }
    }

    @media(min-width:480px) {
        padding-top: 192px;

        .connect {

            .pluses {
                margin-bottom: 30px;

                .number {
                    display: inline-block;
                    vertical-align: top;
                    width: 90px;
                    margin-top: 5px;
                }

                .text {
                    display: inline-block;
                    vertical-align: top;
                    width: 280px;
                }
            }

            .title {
                margin-top: 55px;
                padding-top: 44px;
                width: 300px;
            }

            .content {
                width: auto;
            }
        }

        form {
            padding: 0 85px 0 110px;
            margin-top: 135px;
        }
    }
}

section.team {
    margin-top: -90px;

    .wrapper {
        padding-bottom: 75px;

        &:after {
            content: "";
            position: absolute;
            bottom: 5px;
            right: 5px;
            width: 102px;
            height: 80px;
            background: url(../img/car.svg) center center no-repeat;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            transform: scale(-1, 1) rotate(-7deg);

            @media(min-width: 480px) {
                right: 10px;
                bottom: 0;
            }
        }
    }

    h2 {
        margin-bottom: 25px;
    }

    ul {
        width: 220px;
        margin: 0 auto;

        li {
            display: block;
            margin-bottom: 40px;
            width: 220px;
            position: relative;
            padding-top: 195px;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }

            .desc {
                color: #1d1d1d;
                background: linear-gradient(to right, #ffa800, #ff8400);
                padding: 25px 10px 25px 25px;
                transform: matrix(1, 0.124, 0, 1, 0, 0);
                margin: 0 auto;
            }

            .name {               
                display: block; 
                transform: matrix(1, -0.124, 0, 1, 0, 0);
                font-size: 16px;
                line-height: 20px;
                font-family: @black;
                position: relative;
                padding-bottom: 18px;
                margin-bottom: 11px;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: #1d1d1d;
                    height: 2px;
                    width: 90px;
                }
            }

            .email, .post {
                font-size: 16px;
                line-height: 16px;
                display: block;
                transform: matrix(1, -0.124, 0, 1, 0, 0);
                color: #1d1d1d;
                margin: 10px 0;
            }
        }

        @media(min-width: 480px) {
            width: 100%;

            li {
                float: left;

                &:nth-child(2n) {
                    margin-left: 20px;
                }
            }
        }
    }
}

footer {
    background: #212121;
    position: relative;
    margin-top: 98px;
    padding: 1px 0 1px;

    @media(min-width: 480px) {
        margin-top: 93px;
    }

    .wrapper {
        z-index: 3;
    }

    .wrapper.top {
        margin-top: -80px;

        @media(max-width: 767px) {
            width: 320px;
        }
    }

    &:after {
        content: "";
        position: absolute;
        top: -235px;
        left: 50%;
        margin-left: -960px;
        border-right: 1920px solid transparent;
        border-bottom: 235px solid #212121;
        z-index: 2;
    }

    &:before {
        content: "";
        position: absolute;
        top: -236px;
        left: 50%;
        margin-left: -960px;
        border-right: 1920px solid transparent;
        border-bottom: 235px solid #fff;
        z-index: 1;
    }

    .logo {
        margin-bottom: 5px;
    }

    .phone, .email {
        font-size: 18px;
        line-height: 20px;
        display: block;
        margin-left: 55px;
        padding-left: 40px;
        text-decoration: none;
        margin-bottom: 20px;
        position: relative;

        .icon {
            position: absolute;
            left: 0;
            bottom: 0;
            fill: #ff8400;
            width: 17px;
            height: 17px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .email {
        text-decoration: underline;
        
        .icon {
            left: 2px;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .button {
        width: 250px;
        display: block;
        margin: 25px auto 30px;
    }

    .hr {
        width: 300px;
        height: 1px;
        background: #ff8400;
        margin: 0 auto 22px;
    }

    .cards {
        margin: 0 auto 25px;
    }

    .polite_a, .seo_id {
        font-size: 12px;
        line-height: 20px;
        display: block;
        margin-bottom: 25px;

        @media(min-width: 480px) {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .seo_id {
        span {
            display: inline-block;
            vertical-align: middle;
            text-decoration: underline;
            margin-right: 12px;
        }

        img {
            display: inline-block;
            vertical-align: middle;
        }

        &:hover span {
            text-decoration: none;
        }

        @media(min-width: 480px) {
            margin-left: 30px;
        }
    }
}

.burger {
    position: fixed;
    width: 100%;
    height: 40px;
    background: url(../img/burger.png) left 10px center no-repeat #212121;
    top: 0;
    left: 0;
    z-index: 9;
}

.menu {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    z-index: 10;
    background: #212121;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.35);
    overflow: hidden;
    transition-duration: 0.5s;
    padding-bottom: 20px;

    .burger {
        background: url(../img/burger_close.png) left 15px center no-repeat #212121;
        display: none;
    }

    .logo {
        height: 148px;
        border-bottom: 1px solid #363636;
        display: block;
        line-height: 148px;
        margin-top: 40px;

        img {
            width: 215px;
            height: 61px;
            max-width: none;
            display: inline-block;
            vertical-align: middle;
            transition-duration: 0.5s;
            margin-left: 52px;
        }
    }

    ul {
        width: 320px;
        transition-duration: 0.5s;
        margin-bottom: 38px;

        li {
            padding: 5px 5px 5px 0;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                height: 1px;
                width: 260px;
                background: #363636;
            }

            a {
                display: block;
                line-height: 54px;
                padding-left: 60px;          
                transition-duration: 0.5s;

                .icon {
                    width: 35px;
                    height: 35px;
                    fill: #fff;
                    position: absolute;
                    left: 11px;
                    top: 50%;
                    transform: translateY(-50%);
                    fill-rule: evenodd;
                }

                span {
                    font-size: 16px;
                    font-family: @black;
                    line-height: 20px;
                    text-decoration: none;
                    vertical-align: middle;
                    display: inline-block;
                    transition-duration: 0.5s;
                    padding-left: 15px;
                }
            }

            &:hover {
                a {
                    background-color: #ffa800;
                }
            }
        }
    }

    .email, .phone {
        display: block;
        position: relative;
        margin-left: 75px;
        padding-left: 38px;
        color: #ff8400;
        font-size: 14px;
        line-height: 25px;
        margin-bottom: 15px;
        white-space: nowrap;
        opacity: 0;
        transition-duration: 0.5s;

        .icon {
            fill: #ff8400;
            height: 25px;
            width: 25px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .phone {
        background-position-y: -39px;
        margin-bottom: 25px;
    }

    .button {
        width: 240px;
        margin-left: 40px;
        display: block;
        opacity: 0;
        transition-duration: 0.5s;
    }

    &.opened {
        overflow: auto;
        width: 320px;

        .burger {
            display: block;
        }

        .email, .phone, .button {
            opacity: 1;
        }
    }

    .up {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        top: 670px;

        .icon {
            width: 20px;
            height: 11px;
            position: absolute;
            bottom: 50px;
            left: 50%;
            fill: #fff;
            transform: translate(-50%);
        }

        @media (max-height: 800px) {
            position: relative;
            display: block;
            height: 100px;
            top: 0;
            margin-top: 20px;
        }
    }
}